import React from 'react';
import './SecondFrame.css'
import { Images } from '../../../utils/Images'

export default function SecondFrame(props) {
    
    return (
        <div className='second-contain'>
            <div className='second-wrap'>
                <div className='second-img-wrap'>
                    <img src={Images.home.secondtGif} alt='' className='second-img' />
                </div>
                <div className='second-right'>
                    <div className='second-title-wrap'> 
                        <div className='second-title'>就医笔记</div>
                        <div className='second-sub'>
                            海量用户分享真实就医经验，篇篇有干
                            <br/>
                            货，助您看病不走弯。
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}