import React,{useState,useEffect} from 'react';
import {Tools} from '../../../utils'
import {LoginApi} from '../../../api'

function WechatFirstLogin(props)  {

  const [tel, onTelChange] = useState('');
  const [verCode, onVerCodeChange] = useState('');
  const [waitTime, changeWaitTime] = useState(60000);
  const [sendClick, changeSendClick] = useState(false);
  const [btnDisabled, changeBtnDisabled] = useState(true);
  const [errTip, setErrTip] = useState('');
  
  useEffect(()=>{
    let interval
    // 开始倒计时
    if (waitTime && sendClick) {
        interval = setInterval(() => {
            changeWaitTime(preSecond => {
                if (preSecond > 1000) {
                    return preSecond - 1000
                } else {
                    clearInterval(interval)
                    changeSendClick(false);
                    return 60000
                }
            })
        }, 1000)
    }
    return () => clearInterval(interval)
  },[waitTime]);

  const getMsgCode = () => {
    getTimer(() => getVifiCode())
  };

  // 获取验证
  const getVifiCode = async () => {
      try {
          let res = await LoginApi.getMsgCode(tel)
          console.log(res)
      }catch (e) {
          console.log(e,'error')
      }
  }

  const getTimer = (callback) => {
    if (!Tools.verifyTel(tel)) {
      setErrTip('*请输入正确手机号码')
      return
    }

    changeSendClick(true);
    callback  && callback();
    changeWaitTime(waitTime - 1000);
  };

  // 修改按钮状态
  const allChangeBtnDisabled = (a,b) => {
    if(a !=='' && a.trim().length > 0 && b!=='' && b.trim().length > 0) {
        changeBtnDisabled(false)
    }else {
        changeBtnDisabled(true)
    }
  };

  //校验手机号
  const onTelTextChange = (val) => {
    
    // setErrTip('')
    onTelChange(val);
    allChangeBtnDisabled(val,verCode);
  };
  const onTelTextBlur = () => {
    if(!Tools.verifyTel(tel)) {
      setErrTip('*请输入正确手机号码')
    }
  }

  const onLoginClick = async() => {
    try {
      const res = await LoginApi.accountLogin(tel,verCode,props.wechatData)
      if(res && res.success) {
        localStorage.setItem("access_token", res.data.access_token)
        localStorage.setItem("userInfo", JSON.stringify(res.data.user))
        localStorage.setItem("isReal", res.data.user.identity_authentication)
        props.onCloseModal()
        props.linkClick()
      }
    }catch(e) {
      console.log(e, "err")
    }
  }

    return (
        <div>
            <div>
              <div className='logo-wrap'>
                <div className='title-wrap'>
                  <span className='modal-title'>请绑定手机号码</span>
                </div>
              </div>
              <div className='input-wrap' style={{ borderColor: errTip !== '' ?  '#F16765' : '#DCDFE6'}}>
                <input 
                  className='num-input' 
                  placeholder='请输入手机号'
                  max={11}
                  type='number'
                  value={tel}
                  onChange={(e) => onTelTextChange(e.target.value)}
                  onFocus={() => {
                    setErrTip('')
                  }}
                  onBlur={() => onTelTextBlur()}
                />
              </div>
              <div className='input-wrap-bottom'>
                <input 
                  className='code-input' 
                  placeholder='请输入短信验证码'
                  type='text'
                  value={verCode}
                  maxLength={6}
                  onChange={(e) => {
                    onVerCodeChange(e.target.value)
                    allChangeBtnDisabled(e.target.value,tel);
                  }}
                />
                <div className='line-icon' />
                <button 
                  disabled={sendClick}
                  className='code-btn'
                  onClick={() => getMsgCode()}
                >
                  {sendClick ? `${waitTime/1000}s后重发` : '获取验证码'}
                </button>
              </div>
              <div className='err-tip'>{errTip}</div>
              <button 
                disabled={btnDisabled}
                className='submit-button'
                onClick={() => onLoginClick()}>
                绑定
              </button>
            </div>
        </div>
    )
}

export default WechatFirstLogin