import React, {useState, useEffect} from 'react'
import  * as styles from './NoteList.module.css'
import {Images} from '@/utils'
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import NoteAction from "@/redux/note/NoteAction";
import { Tabs, Pagination, Button } from 'antd';
import {NoteCard} from './NoteCard'
import {NoteApi} from '@/api/index'
import { Loading,Footer,ToTop } from '@/components'
import queryString from "query-string";

const { TabPane } = Tabs;
const perPage = 10

function NoteList(props) {
    let url = props.location.search;
    let params = queryString.parse(url);
    const { noteTotal, draftTotal } = props.noteState
    const [tabLoading, setTabLoading] = useState(false)
    const [tabKey, setTabKey] = useState(params?.tabKey || '1')
    const [page, setPage] = useState(1)
    const [draftPage, setDraftPage] = useState(1)
    const [noteList, setNoteList] = useState(props.noteState.noteList)
    const [draftNotes, setDraftNotes] = useState(props.noteState.draftNotes)
    const tabChange = (value) => {
        setTabKey(value)
    }
    const paginationChange = (page, perPage) => {
        if(tabKey == 1) {
            setPage(page)
            getNoteList(page)
        } else {
            setDraftPage(page)
            getNoteDraftList(page)
        }
    }
    const getNoteList = (currentpage = page) => {
        setTabLoading(true)
        NoteApi.getNoteList(currentpage, perPage).then((res)=> {
            if(res?.success) {
                setNoteList(res?.data)
                page == 1 && props.getNoteList(res.data)
                props.getNoteTotal(res.paginationMeta?.totalCount || 0)
            }
            setTabLoading(false)
        })
    }
    const getNoteDraftList = (currentpage = draftPage) => {
        setTabLoading(true)
        NoteApi.getNoteDraftList(currentpage, perPage).then((res)=> {
            if(res.success) {
                setDraftNotes(res.data)
                draftPage == 1 && props.getDraftNote(res.data)
                props.getDraftTotal(res.paginationMeta?.totalCount || 0)
            }
            setTabLoading(false)
        })
    }
    const getEmptyNote = ()=> {
        return (
            <div className={styles.emptyView}>
                <img className={styles.emptyIcon} src={Images.note.emptyList} alt='' />
                <div className={styles.emptyText}>暂无笔记，快去写一篇吧</div>
                <Link to='/note'><Button type="primary" className={styles.empytBtn}>发布笔记</Button></Link>
            </div>
        )
    }
    const getNoteLength = () => {
        return tabKey == 1 ? noteList.length : draftNotes.length
    }
    useEffect(()=> {
        let isUnmount = false
        if(!isUnmount) {
            getNoteList()
            getNoteDraftList()
        }
        return () => { isUnmount = true }
    }, [])
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.tabContent}>
                    <Tabs defaultActiveKey={tabKey} onChange={tabChange} className={styles.antTabsNav} size='large'>
                        <TabPane tab={`发布的笔记（${noteTotal}）`} key="1" >
                            {tabLoading ? <Loading /> :
                                noteList.length == 0 ? getEmptyNote() : noteList.map((value) => {
                                    return <NoteCard
                                    data={value}
                                    isDraft={false}
                                    key={value.article_id}
                                    deleteCallBack={()=>paginationChange(1, perPage)}/>
                                })}
                        </TabPane>
                        <TabPane tab={`草稿箱（${draftTotal}）`} key="2" >
                            {tabLoading ? <Loading /> :
                            draftNotes.length == 0 ? getEmptyNote() : draftNotes.map((value) => {
                                return <NoteCard
                                data={value}
                                isDraft={true}
                                key={value.draft_id}
                                deleteCallBack={()=>paginationChange(1, perPage)}/>
                            })}
                        </TabPane>
                    </Tabs>
                </div>
                <div className={styles.pagination}>
                    {getNoteLength() != 0 && <Pagination defaultCurrent={1}
                    total={tabKey == 1 ?noteTotal:draftTotal}
                    defaultPageSize={perPage}
                    onChange={paginationChange}
                    current={tabKey == 1 ?page:draftPage} />}
                </div>
            </div>
            <Footer style={{paddingTop: '80px',marginBottom: '40px'}}/>
            <ToTop onToTopClick={() => window.scrollTo(0, 0)}/>
        </div>
    )
}
export default connect(state => {
    return {
        noteState: state.noteState
    }
}, dispatch => {
    return {
        getNoteList: (data) => dispatch(NoteAction.getNoteList(data)),
        getDraftNote: (data) => dispatch(NoteAction.getDraftNote(data)),
        getNoteTotal: (data) => dispatch(NoteAction.getNoteTotal(data)),
        getDraftTotal: (data) => dispatch(NoteAction.getDraftTotal(data)),
    }
})(NoteList)
