import React from 'react';
import './SixthFrame.css'
import { Images } from '../../../utils/Images'

export default function SixthFrame(props) {


    return (
        <div className='six-contain'>
            <div className='contain-wrap'>
                <span className='download-title'>扫码下载APP</span>
                <div className='download-wrap'>
                    <div className='download-item'>
                        <span className='download-text'>iOS版</span>
                        <img src={Images.home.iosDown} alt='' className='download-icon' />
                    </div>
                    <div className='download-item'>
                        <span className='download-text'>安卓版</span>
                        <img src={Images.home.androidDown} alt='' className='download-icon' />
                    </div>
                </div>
                <div className='pact-wrap'>
                    <span className='pact-text' onClick={() => props.showProtocolModal()}>用户协议</span>
                    <span className='pact-text' onClick={() => props.showPrivacyModal()}>隐私政策</span>
                </div>
                <div className='footer-wrap'>
                    
                        <div className='info-text'>
                            Copyright © 2019 上海忞竺科技有限公司
                            <br/>沪ICP备: 19034980号
                        </div>
                   
                </div>
            </div>
        </div>
    );
}