import React from 'react';
import './FifthFrame.css'
import { Images } from '../../../utils/Images'

export default function FifthFrame(props) {

    return (
        <div className='five-contain'>
            <div className='fifth-wrap'>
                <div className='fifth-left'>
                    <div className='fifth-title-wrap'> 
                        <div className='fifth-title'>互医榜单</div>
                        <div className='fifth-sub'>看病之前帮您找对医生，关注互医医院<br/>榜、互医医生榜和专科排行榜，为精准<br/>就医提供重要参考。</div>
                    </div>
                </div>

                <div className='fifth-img-wrap'>
                    <img src={Images.home.fifthGif} alt='' className='fifth-img' />
                </div>
                
            </div>
        </div>
    );
}