import React, {useState,useEffect}  from 'react'
import { BrowserRouter as Router, Link, useLocation} from 'react-router-dom';
import { Images } from '../../utils'
import  * as styles from './MenuItem.module.css'
import LoginModal from '../LoginModal/LoginModal'
import { UserApi } from '../../api';


function MenuItem(props) {

  const loginStatus = localStorage.getItem('access_token')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [centerModal,setCenterModal] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [notReadCount, setNotReadCount] = useState(0)
  const [routerName, setRouterName] = useState('home')
  

  const onLoginClick = () => {
    setLoginModal(!loginModal)
  }

  const loginOut = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem("isReal")
    setCenterModal(false)
  }

  const {pathname} = useLocation();

  const getNotReadNews = async() => {
    try {
      const res = await UserApi.getNotReadNews();
      if(res && res.success) {
        console.log(res)
        setNotReadCount(res.data)
      }
    }catch(e) {
      console.log(e, 'err')
    }
  }


  return(
      

      
      <div className={styles.menu}>
        <div className={styles.menu_wrap}>
        <div className={styles.menu_left}>
            <Link to='/home'>
              <img src={Images.home.logo} alt='' className={styles.logo}/>
            </Link>
            <Link to='/home'>
              <span className={styles.title}>互医网</span>
            </Link>
            
        </div>
        <div className={styles.menu_right}>
            <ul className={styles.actions}>
                <li className={pathname ==='/home' ? styles.active : ''}>
                  <Link
                    className={styles.link_word}
                    to='/home'
                  >
                    首页
                  </Link>
                </li>
                <li className={pathname ==='/note' ? styles.active : ''}>
                  <Link
                    className={styles.link_word}
                    to={loginStatus ? '/note' : pathname}
                    target={loginStatus ? '_blank' : ''}
                    onClick= {() => {
                      if(!loginStatus) {
                        onLoginClick()
                        setRouterName('note')
                      }
                    }}
                  >
                    发布笔记
                  </Link>
                </li>
                <li className={pathname ==='/noteList' ? styles.active : ''}>
                  <Link
                    className={styles.link_word}
                    to={loginStatus ? '/noteList' : pathname}
                    onClick= {() => {
                      if(!loginStatus) {
                        onLoginClick()
                        setRouterName('noteList')
                      }
                    }}
                  >
                    笔记管理
                  </Link>
                </li>
                <li className={pathname ==='/album' ? styles.active : ''}>
                  <Link
                    className={styles.link_word}
                    to={loginStatus ? '/album': pathname}
                    onClick= {() => {
                      if(!loginStatus) {
                        onLoginClick()
                        setRouterName('album')
                      }
                    }}
                  >
                    相册管理
                  </Link>
                </li>
            </ul>
            {!!!loginStatus
              ?<button
                className={styles.sign_btn }
                onClick={() => onLoginClick()}
              >
                登录/注册
              </button>

            :<div className={styles.user_wrap}
              onMouseOver={() => {
                setCenterModal(true)
                getNotReadNews()
              }}
              onMouseLeave={() => {
                setCenterModal(false)
              }}
            >
              <div className={styles.user_info}>
                {
                  userInfo?.avatar
                    ? <img className={styles.avatar}  src={userInfo.avatar} alt='' />
                    : <img className={styles.avatar}  src={Images.home.defaultAvatar} alt='' />
                }

                <span className={styles.center_text}>个人中心</span>
                <img 
                  className={!centerModal ? styles.triangle_icon : styles.transfromY} 
                  // src={centerModal ? Images.home.triangleUp : Images.home.triangleDown}  
                  src={Images.home.triangleDown} 
                  
                  alt='' 
                />

              </div>
              {centerModal && <div className={styles.modal_view}
                onMouseOver={() => {
                  setCenterModal(true)
                }}
                onMouseLeave={() => {
                  setCenterModal(false)
                }}
              >
                <div>
                <img className={styles.triangle} src={Images.home.headerLine} alt='' />
                </div>

                <div className={styles.modal_item_top}>
                  <Link
                    className={styles.modal_text}
                    to='/setting'
                    onClick={() => {
                      setCenterModal(false)
                    }}
                  >
                    账号管理
                  </Link>
                </div>
                <div className={styles.modal_item}>
                  <Link
                    className={styles.modal_text}
                    to='/mynews'
                    onClick={() => {
                      setCenterModal(false)
                    }}
                  >消息评论</Link>
                  { notReadCount !== 0 && <div className={styles.new_text}>新</div> }
                </div>
                <div className={styles.modal_item}>
                  <Link className={styles.modal_text} to='/home' onClick={() => loginOut()}>退出</Link>
                </div>
              </div>}
            </div>
          }
        </div>
        <LoginModal
            isOpen={loginModal}
            setModalStatus={(value) => {
              setLoginModal(value)
            }}
            linkClick={() => {
              if(routerName === 'note') {
                window.open('/#/'+routerName)
              }else {
                window.location.href = '/#/'+routerName
              }
              
            }}
          />
          </div>
      </div>

  )

}

export default MenuItem
