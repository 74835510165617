import React from 'react';
import './ThirdFrame.css'
import { Images } from '../../../utils/Images'

export default function ThirdFrame(props) {

    return (
        <div className='third-contain'>
            <div className='third-wrap'>
                <div className='third-left'>
                    <div className='third-title-wrap'> 
                        <div className='third-title'>医院和医生信息</div>
                        <div className='third-sub'>汇集全国千家重点医院，上万名权威医<br/>生，为您找到找好医院好医生。</div>
                    </div>
                </div>

                <div className='third-img-wrap'>
                    <img src={Images.home.thirdGif} alt='' className='third-img' />
                </div>
                
            </div>
            
        </div>
    );
}