import React, {useState} from 'react'
import { Images } from '@/utils'
import  * as styles from './ToTop.module.css'


function ToTop(props) {

    const [showDownlaod, setShowDownload] = useState(false)

    return (
        <div className={`${styles.to_top_view} ${[props.className]}`}>

            {props.showFillIn && <img 
                className={styles.fillIn} 
                src={Images.modal.fillIn} 
                alt='' 
                onClick={() => props.onFillInClick()}
            />}
            <div className={styles.download_wrap}>
                <img 
                    className={styles.download} 
                    src={Images.modal.download} 
                    alt=''
                    onMouseOver={() => setShowDownload(true)}
                    onMouseOut={() => setShowDownload(false)}
                />
                {showDownlaod && <div className={styles.download_item}>
                    <div className={styles.item}>
                        <div className={styles.title}>ios版下载</div>
                        <img className={styles.download_icon} src={Images.home.iosDown} alt='' />
                    </div>
                    <div>
                        <div className={styles.title} >安卓版下载</div>
                        <img className={styles.download_icon} src={Images.home.androidDown} alt='' />
                    </div>
                </div>}
            </div>
            <img 
                className={styles.to_top} 
                src={Images.modal.toTop} 
                alt=''
                onClick={() => props.onToTopClick()}
             />
        </div>
    )
}

export default ToTop