import React, {useState,useEffect}  from 'react'
import { List, Pagination, Input, Form,message } from 'antd';
import 'antd/dist/antd.css';
import  * as styles from './MyNews.module.css'
import './reset.css'
import { Tools } from '../../../utils';
import { Images } from '../../../utils/Images';
import {UserApi, MyNewsApi} from '../../../api'
import { Footer, ToTop } from '../../../components'
import { Link } from 'react-router-dom';


const { TextArea } = Input;

function MyNews() {

  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [notReadNews, setNotReadNews] = useState(0)
  const per_page = 10
  const [form] = Form.useForm();
  const [loadding, setLoadding] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  useEffect(() => {
    getNotReadNews()
    getNews()
  },[currentPage])



  const getNotReadNews = async() => {

    try {
      const res = await UserApi.getNotReadNews();
      if(res && res.success) {
        setNotReadNews(res.data)
      }
    }catch(e) {
      console.log(e, 'err')
    }
  }
  const getNews = async() => {
    setLoadding(true)
    try{
      const res = await MyNewsApi.getNews(currentPage,per_page)
      if(res && res.success) {
        const newData = initData(res.data)
        setData(newData)
        setTotalCount(res.paginationMeta.totalCount)
        setLoadding(false)
      }
    }catch(e){
      console.log(e, 'err')
    }
  }

  const initData = (data) => {
    let newAry = [] ;
    if(Array.isArray(data) && data.length > 0) {
      newAry = data.map((item) => {
        item.show_reply = false
        return item
      })
      return newAry
    }
    return newAry
  }

  const onLikeClick = async(info) => {
    try{
      if(!info.is_like) {
        const res = await MyNewsApi.sendLike(info.src_id)
        if(res && res.success){
            message.info('点赞成功')
            const newData = data.map(item => {
              if(info.id === item.id) {
                  item.is_like = true
              }
              return item
            })
            setData(newData)
        }
      }else {
        const res = await MyNewsApi.cancleLike(info.src_id)
        if(res && res.success){

            message.info('取消点赞')
            const newData = data.map(item => {
              if(info.id === item.id) {
                  item.is_like = false
              }
              return item
            })
            setData(newData)
        }
      }
    }catch(e) {
      console.log(e, 'err')
    }
  }

  const onShowReplyClick = (info) => {
    const newData = data.map(item => {
      if(info.id === item.id) {
        item.show_reply = true
      }
      return item
    })
    setData(newData)
  }

  const onCancleClick = (info) => {
    const newData = data.map(item => {
      if(info.id === item.id) {
        item.show_reply = false
      }
      return item
    })
    setData(newData)
  }

  const onSendClick =(val, info) => {
    const id = info.id;
    const values = val[id]
    if(values === '' || values === null || values === undefined ) {
      return message.info('不说两句吗？')
    }
    sendComment(values, info)

  }


  const sendComment = async(val,info) => {
    try{
      let owner_id = info.source_id,
      content = val,
      parent_id = info.src_id,
      source_id = info.first_comment_id,
      classify = info.source_type ;

      const res = await MyNewsApi.addComments(owner_id,content,parent_id,source_id,classify)
      if(res && res.success) {
        message.info('回复成功')
        const newData = data.map(item => {
          if(info.id === item.id) {
            item.show_reply = false
          }
          return item
        })
        setData(newData)
      }

    }catch(e) {
      console.log(e, 'err')
    }
  }

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  const HeaderItem = () => {
    return (
      <div className={styles.header_wrap}>
        {notReadNews === 0
          ?'消息评论'
          :'消息评论（' + notReadNews +'）'
        }
      </div>
    )
  }


  const RenderItem = (props) => {
    const {newsInfo} = props
    return (
      <div className={styles.news_wrap}>
        <div className={styles.news_header}>
          <img
            className={styles.avatar}
            src={newsInfo.send_user?.avatar ? newsInfo.send_user?.avatar : Images.home.defaultAvatar}
            alt=''
          />
          <div className={styles.name_wrap}>
            <span className={styles.user_name}>{newsInfo.send_user.name}</span>
            <span className={styles.user_time}>{Tools.getTimeFromNow(newsInfo.create_time)}</span>
          </div>
        </div>
        {
          newsInfo.action === 2
          ?(
            <div className={styles.reply_wrap}>
              <span className={styles.reply_tip}>评论了我的笔记：</span>
              <span className={styles.reply_del}>{newsInfo.content}</span>
            </div>
          ):(
            <div className={styles.reply_wrap}>
              <span className={styles.reply_tip}>
                回复了我：
                <span className={styles.reply_del}>{newsInfo.content + '//'}</span>
                {"@" + newsInfo.my_name+ "："}
                <span className={styles.reply_del}>{newsInfo.parent_content}</span>
              </span>



            </div>
          )
        }

        <div className={styles.like_reply}>
          <div
            className={styles.like_wrap}
            onClick={() => onLikeClick(newsInfo)}
          >
            <img
              className={styles.like_icon}
              src={newsInfo.is_like ?Images.news.likeIcon : Images.news.unlikeIcon}
              alt=''
            />
            <span
              className={styles.like_text}
              style={{color: newsInfo.is_like ? '#40A6FF' : '#162533'}}
            >赞</span>
          </div>
          <span
            className={styles.like_text}
            onClick={() => onShowReplyClick(newsInfo)}
          >
            回复
          </span>
        </div>
        {newsInfo.show_reply &&
          <div className={styles.input_wrap}>
            <Form
              preserve={false}
              form={form}
              onFinish={(values) => onSendClick(values, newsInfo)}
              className='antd-form'
            >
              <Form.Item label="reply" name={newsInfo.id}>
                <TextArea
                  placeholder={"回复" + newsInfo.send_user.name}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  style={{fontSize: '16px', color: '#162533', lineHeight: '22px'}}
                />
              </Form.Item>
              <div className={styles.btn_wrap}>
                <button
                  className={styles.btn_item}
                  style={{backgroundColor: '#DCDFE6'}}
                  onClick={() => onCancleClick(newsInfo)}
                >
                  取消
                </button>
                <Form.Item>
                  <button className={styles.btn_item} >发送</button>
                </Form.Item>

              </div>
            </Form>
          </div>
        }
        <Link to={`/noteList/${newsInfo.source_id}`} target='_blank'>
          <div className={styles.note_info}>
            <span className={styles.note_text}>{newsInfo.source_content}</span>
            <img className={styles.grey_goin} src={Images.news.greyGoin} alt='' />
          </div>
        </Link>
      </div>
    )
  }

  if(Array.isArray(data) && data.length === 0 && !loadding) {
    return (
      <div className={styles.container}>
          <div className={styles.empty_view}>
            <img className={styles.empty_icon} src={Images.album.emptyIcon} alt='' />
            <div className={styles.emptyText}>暂无消息</div>
          </div>
      </div>
    )
  }


  return(
    <div className={styles.container}>
      <div className={styles.news_contant}>
          <List

            loading={loadding}
            dataSource={data}
            style={{backgroundColor: 'white'}}
            header={<HeaderItem />}
            renderItem={item =>(
              <RenderItem
                key={item.id}
                newsInfo={item}
              />
            )}
          />
      </div>
      <div className={styles.page_view}>
        <Pagination
          defaultCurrent={currentPage}
          pageSizeOptions='10'
          total={totalCount}
          showSizeChanger={false}
          onChange={page => onPageChange(page)}
        />
      </div>

      <Footer style={{paddingBottom: '26px',paddingTop: '62px'}} />


      <ToTop onToTopClick={() => window.scrollTo(0, 0)}/>
    </div>
  )

}

export default MyNews
