import React from 'react'
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import HomePage from '../pages/Home/HomePage'
import AccountSetting from '../pages/PersonalCenter/AccountSetting/Index'
import {MenuItem} from '../components'
import Album from '../pages/Album/Index'
import NotePage from "../pages/Note/NotePage";
import MyNews from '../pages/PersonalCenter/MyNews/Index'
import NoteList from '@/pages/List/NoteList'
import NoteDetail from '@/pages/List/NoteDetail'
import Eval from '@/pages/Eval/Index'

const BasicRoute = () => (
    <Router>
        <MenuItem/>
        <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path='/home' component={HomePage} />
            <Route exact path='/noteList' component={NoteList} />
            <Route path='/noteList/:noteId' component={NoteDetail} />
            <Route path='/setting' component={AccountSetting} />
            <Route path='/album' component={Album} />
            <Route path='/note' component={NotePage} />
            <Route path='/mynews' component={MyNews} />
            <Route path='/eval' component={Eval} />
            <Route path="/empty" component={null} key="empty" />
        </Switch>

    </Router>
)

export default BasicRoute
