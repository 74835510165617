import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './HomePage.css';
import FirstFrame from './FirstFrame/FirstFrame';
import SecondFrame from './SecondFrame/SecondFrame';
import ThirdFrame from './ThirdFrame/ThirdFrame';
import FourthFrame from './FourthFrame/FourthFrame'
import SixthFrame from './SixthFrame/SixthFrame';
import FifthFrame from './FifthFrame/FifthFrame';
import {
  ProtocolModal,
  PrivacyModal,
  BindModal,
} from '../../components'
import {Images} from '@/utils'


// const SEL = 'custom-section';
// const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {

//   require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.

};

// const originalColors = ['#ff5f45', '#0798ec', '#fc6c7c', '#435b71', 'orange', 'blue', 'purple', 'yellow'];

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    //   sectionsColor: [...originalColors],
    //   fullpages: [

    //   ],
      
      protocolModal: false,
      privacyModal: false,
      bindModal: false,
    };
  }

  onLeave(origin, destination, direction) {
    // console.log('onLeave', { origin, destination, direction });
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
    // this.forceUpdate();

  }

  moveSectionDown() {
    // fullpage_api.moveSectionDown();
  }



  render() {

    return (
      <div className='home-wrap'>
      <div className="home-content">
        <ReactFullpage
          //debug /* Debug logging */

          // Required when using extensions
          pluginWrapper={pluginWrapper}

          // fullpage options
          licenseKey={null} // Get one from https://alvarotrigo.com/fullPage/pricing/
          navigation
          // anchors={['FirstFrame','SecondFrame','ThirdFrame','FourthFrame','FifthFrame', 'SixthFrame',]}
          // sectionSelector={SECTION_SEL}
          onLeave={this.onLeave.bind(this)}
          //   sectionsColor={this.state.sectionsColor}

          render={({ state, fullpageApi })  => (
            <ReactFullpage.Wrapper>
                <div className="section">
                    <FirstFrame />
                </div>
                <div className="section">
                    <SecondFrame />
                </div>
                <div className="section">
                    <ThirdFrame  />
                </div>
                <div className="section">
                    <FourthFrame />
                </div>
                <div className="section">
                    <FifthFrame />
                </div>
                <div className="section">
                    <SixthFrame
                      showProtocolModal={() => {
                        this.setState({
                          protocolModal: !this.setState.protocolModal
                        })
                      }}
                      showPrivacyModal={() => {
                        this.setState({
                          privacyModal: !this.setState.privacyModal
                        })
                      }}
                    />
                    
                </div>
            </ReactFullpage.Wrapper>
          )}
        />
          <ProtocolModal
            isOpen={this.state.protocolModal}
            onCloseModal={() => {
              this.setState({
                protocolModal: false,
              })
            }}
          />
          <PrivacyModal
            isOpen={this.state.privacyModal}
            onCloseModal={() => {
              this.setState({
                privacyModal: false,
              })
            }}
          />
          <BindModal
            isOpen={this.state.bindModal}
            onCloseModal={() => {
              this.setState({
                bindModal: false,
              })
            }}
          />

      </div>
      </div>
    );
  }
}


export default HomePage
