import React, { useState,useEffect }  from 'react';
import './FirstFrame.css'
import { Images } from '../../../utils/Images'

export default function FirstFrame() {

    const [iosShow ,iosOver]  = useState("none")
    const [iosColor,iosColorChange]  = useState("#40A6FF")

    const [androidShow ,androidOver] = useState("none")
    const [androidColor ,androidColorChange] = useState("#59CDFF")
   
    const [refresh, setRefresh] = useState(false);


    useEffect( () => {
        
        refresh && setTimeout(() => setRefresh(false))
        
    },[refresh]);
    


    return (
        <div className='first-contain'>
            <div className='first-wrap'>
                <div className='first-left'>
                    <div className='first-title-wrap'> 
                        <div className='first-title'>就医经验分享与指导平台</div>
                        <div className='first-sub'>在这里，有数万计的用户分享就医体验和就诊攻略。</div>
                    </div>
                    
                    <div className='first-download'>
                        <div className='first-download-item'>
                            <button 
                                className='first-ios-download' 
                                style={{'background': iosColor}}
                                onMouseOver={()=>{
                                    iosOver('block')
                                    iosColorChange('rgb(15,143,255)')
                                }} 
                                onMouseLeave={()=>{
                                    iosOver('none')
                                    iosColorChange('#40A6FF')
                                }}
                                onClick={() => {
                                    window.open('https://apps.apple.com/cn/app/id1516210858',"_blank")
                                }}
                            >
                                ios版下载
                            </button>
                            <img src={Images.home.iosDown} alt='' style={{"display": iosShow}} className='first-ios-icon' />
                        </div>
                        <div className='first-download-item'>
                            < button 
                                className='first-android-download' 
                                style={{'background': androidColor}}
                                onMouseOver={()=>{
                                    androidOver('block')
                                    androidColorChange('rgb(15,143,255)')
                                }} 
                                onMouseLeave={()=>{
                                    androidOver('none')
                                    androidColorChange('#59CDFF')
                                }}
                                onClick={() => {
                                    window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.hyw_app',"_blank")
                                }}
                            >
                                安卓版下载
                            </button>
                            <img src={Images.home.androidDown} alt='' style={{"display": androidShow}} className='first-android-icon' />
                        </div>
                    </div>
                </div>

                <div className='first-img-wrap'>
                    <img src={Images.home.firstGif} alt='' className='first-img' />
                </div>
                
            </div>
        </div>
    );
}