export  const Images = {
    home: {
        androidDown: require('../assets/home/android-download.png'),
        iosDown: require('../assets/home/ios-download.png'),
        topIcon: require('../assets/home/top-icon.png'),
        logo: require('../assets/home/logo.png'),
        firstGif: require('../assets/home/1.gif'),
        secondtGif: require('../assets/home/2.gif'),
        thirdGif: require('../assets/home/3.gif'),
        fourthGif: require('../assets/home/4.gif'),
        fifthGif: require('../assets/home/5.gif'),
        defaultAvatar: require('../assets/home/avatar.png'),
        headerLine: require('../assets/home/header-line.png'),

        triangleDown: require('../assets/home/triangle-down.png'),
        triangleUp: require('../assets/home/triangle-up.png'),
    },
    modal: {
        logoIcon: require('../assets/modal/logo.png'),
        closeIcon: require('../assets/modal/close.png'),
        weChat: require('../assets/modal/wechat.png'),
        download: require('../assets/modal/download-icon.png'),
        fillIn: require('../assets/modal/fillin-icon.png'),
        toTop: require('../assets/modal/totop-icon.png')
    },
    album: {
        deleteIcon: require('../assets/album/delete-icon.png'),
        selectedIcon: require('../assets/album/selected-icon.png'),
        unselectIcon: require('../assets/album/unselect-icon.png'),
        loaddingIcon: require('../assets/album/jiazai01.gif'),
        imgClose: require('../assets/album/img-close.png'),
        emptyIcon: require('../assets/album/empty-icon.png'),
        bigLoad: require('../assets/album/jiazai02.gif'),
    },
    note: {
        rightArrow: require('../assets/note/rightarrow.svg'),
        uploadImg: require('../assets/note/uploadImg.jpg'),
        cardDefault: require('../assets/note/cardDefault.jpg'),
        emptyList: require('../assets/note/empyList.png'),
        satisfiedIcon: require('@/assets/note/satisfied.svg'),
        displeasureIcon: require('@/assets/note/displeasure.svg'),
        blueGoin: require('@/assets/note/blue-goin.png'),
        scoreDefault: require('@/assets/note/score-default.svg'),
        scoreBad: require('@/assets/note/score-bad.svg'),
        scoreOne: require('@/assets/note/score-one.svg'),
        scoreTwo: require('@/assets/note/score-two.svg'),
        scoreThree: require('@/assets/note/score-three.svg'),
        userDefaultAvatar: require('../assets/note/userAvatar.png'),
        hospitalDefault: require('../assets/note/hospitalDefault.png'),
        doctorDefault: require('../assets/note/doctorDefault.png'),
        betterIcon: require('../assets/note/betterIcon.png'),
        goodIcon: require('../assets/note/goodIcon.png'),
        morePoorIcon: require('../assets/note/morePoorIcon.png'),
        poorIcon: require('../assets/note/poorIcon.png'),

        score_one: require('../assets/note/score_one.png'),
        score_two: require('../assets/note/score_two.png'),
        score_three: require('../assets/note/score_three.png'),
        score_four: require('../assets/note/score_four.png'),
        score_five: require('../assets/note/score_five.png'),
        score_six: require('../assets/note/score_six.png'),
        score_four_grey: require('../assets/note/score_four_grey.png'),
        score_six_grey: require('../assets/note/score_six_grey.png'),
    },
    news: {
        greyGoin: require('../assets/news/grey-goin.png'),
        likeIcon: require('../assets/news/like-icon.png'),
        unlikeIcon: require('../assets/news/unlike-icon.png'),
    },
}
