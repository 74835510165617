import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { Images } from '@/utils';



//格式化年月日 yy-mm-dd
const formatTimeTerse = (time) => {
    return dayjs(time).format('YYYY-MM-DD')
}

//验证手机号
const verifyTel = (tel) => {
    const reg = /^[0-9]+.?[0-9]*$/;
    if (reg.test(tel)) {
        return (/^1[3456789]\d{9}$/.test(tel))
    } else {
        return false
    }

}



const changeNum = (obj) => {
  //如果用户第一位输入的是小数点，则重置输入框内容
  if (obj !== '' && obj.substr(0, 1) === '.') {
      obj = "";
  }
  obj = obj.replace(/^0*(0\.|[1-9])/, '$1');//粘贴不生效
  obj = obj.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
  obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
  if (obj.indexOf(".") < 0 && obj !== "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (obj.substr(0, 1) === '0' && obj.length === 2) {
          obj = obj.substr(1, obj.length);
      }
  }
  return obj
}

//格式化距离现在的时间
const getTimeFromNow = (time) => {
    const data = Date.parse(new Date());
    const nowData = dayjs(data).format();
    const hour = dayjs(nowData).diff(dayjs(time),"second")/3600;
    if(0<hour && hour< 1){
        return Math.ceil(hour*60)+"分钟前";
    }else if(1<=hour && hour<24){
        let timer = time.trim().split(" ")[1].toString().split(":")
        return timer[0]+":"+timer[1];
    }else if(hour>=24 && hour<72){
        return  Math.ceil(hour/24)+"天前";
    }else{
        return time.trim().split(" ")[0];
    }
}

const formatDate = (time) => {
    var d = new Date(time)
    return `${d.getMonth()+1}-${d.getDate()}`
}

const getTreatType = (num) => {
    var d = {1: '药物', 2: '手术', 3: '住院', 4: '做检查', 5: '暂未治疗'}
    return d[num]
}

const getNowState = (num) => {
    var d = {1: '已痊愈', 2: '有好转', 3: '病情稳定'}
    return d[num]
}

const getHospitalTotal = (num) => {
    var d = {1: '满意', 0: '不满意'}
    return d[num]
}

const getScoreItemIcon = (text) => {
    var d = {"超赞": 'goodIcon', '很好': 'betterIcon', '一般': 'poorIcon','较差': 'poorIcon', '非常差': "morePoorIcon"}
    return d[text]
}

//评论数，点赞数，查看数过万后的处理
const overTenThousand = (num) =>{
    if(num !== undefined && num !== null) {
        if(num<=10000){
            if(Math.floor(num) === num){
                return num
            }else {
                return num.toFixed(2);
            }
        }else{
            return (num/10000).toFixed(2)+'万';
        }
    } else {
        return ''
    }


}
//得分对应文案
const ScoreText = {
    20: {
        icon: Images.note.score_five,
        text: '非常差'
    },
    55: {
        icon: Images.note.score_six,
        text: '较差'
    },
    65: {
        icon: Images.note.score_two,
        text: '一般'
    },
    85: {
        icon: Images.note.score_three,
        text: '很好'
    },
    100: {
        icon: Images.note.score_four,
        text: '超赞'
    },
}


export const Tools = {
    formatTimeTerse,
    verifyTel,
    changeNum,
    getTimeFromNow,
    formatDate,
    getTreatType,
    getNowState,
    getHospitalTotal,
    getScoreItemIcon,
    overTenThousand,
    ScoreText
}
