import React, {useState,useEffect}  from 'react'
import  * as styles from './Account.module.css'
import {Images} from '../../../utils'
import {UserApi} from '../../../api/index'
import { message, Form, Input } from 'antd';
import { WechatLogin } from '../../../components/LoginModal/components'
import 'antd/dist/antd.css';
import { Footer, ToTop} from '../../../components'
import './reset.css'


function AccountSetting() {

  const userId = JSON.parse(localStorage.getItem('userInfo')).id
  const [userInfo, setUserInfo] = useState({
    id: null,
    avatar: null,
    nickname: null,
    sex: null,
    tel: null,
    isReal: null,
    name: '',
    idCard: '',
    wechat: null,
  })
  const [nameTip, setNameTip] = useState('')
  const [idCardTip, setIdCardTip] =useState('')
  const [wechatLoginShow, setWechatLoginShow] = useState(false)


  useEffect(() => {
    getUserInfo()
  },[]);

  const getUserInfo = async() => {
    try{
      const res = await UserApi.getUserInfo(userId)
      if(res && res.success) {
        setUserInfo({
          avatar: res.data.avatar,
          nickname: res.data.name,
          sex: res.data.sex,
          tel: res.data.phone,
          isReal: res.data.identity_authentication,
          name: res.data.real_name,
          idCard: res.data.id_card,
          wechat: res.data.wechat,
        })
      }
    }catch(e){
      console.log(e, 'err')
    }
  }

  // const onNameBlur = () => {
  //   const name = userInfo.name;
  //   regName(name)
  // }

  // const regName = (name) => {
  //   let flag = true
  //   if(name === '' || name === null) {
  //     setNameTip("请正确填写姓名");
  //     flag = false
  //   }else if(name && name.length >=0 && name.length <=2){
  //     let reg1 = /^[\u4E00-\u9FA5\uF900-\uFA2D]+$/
  //     if(!reg1.test(name)){
  //       setNameTip("请正确填写姓名");
  //       flag = false
  //     }
  //   }else if(name && name.length >2){
  //     let reg2 =  /^[\u4E00-\u9FA5\uF900-\uFA2D]+[\u4E00-\u9FA5\uF900-\uFA2D·s]+[\u4E00-\u9FA5\uF900-\uFA2D]+$/;
  //     if(!reg2.test(name)){
  //       setNameTip("请正确填写姓名");
  //       flag = false
  //     } 
  //   }
  //   return flag
  // }

  // const onCodeBlur = () => {
  //   const idCard = userInfo.idCard
  //   regCode(idCard)
  // }

//   const regCode = (idCard) => {
//     let flag = true
//     if(idCard && idCard.length===15){
//         if(parseInt(idCard.substring(6,10))%4===0 || parseInt(idCard.substring(6,10))%400===0){
//             let reg1 = /[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/
//             if(!reg1.test(idCard)){
//                 setIdCardTip("请正确填写身份证号");
//                 flag = false
//             }
//         }else{
//             let reg2 = /[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/
            
//             if(!reg2.test(idCard)){
//               setIdCardTip("请正确填写身份证号");
//               flag = false 
//             }
            
//         }

//     }else if(idCard && idCard.length===18){
//         if(parseInt(idCard.substring(6,10))%4===0 || parseInt(idCard.substring(6,10))%400===0){
//             let reg3 = /[1-9][0-9]{5}(19[0-9]{2}|20[0-9]{2})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/
            
//             if(!reg3.test(idCard)){
//               setIdCardTip("请正确填写身份证号");
//               flag = false
//             }
//         }else{
//             let reg4 = /[1-9][0-9]{5}(19[0-9]{2}|20[0-9]{2})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/
//             if(!reg4.test(idCard)){
//               setIdCardTip("请正确填写身份证号");
//               flag = false 
//             }
//         }
//     }else {
//       setIdCardTip("请正确填写身份证号");
//       flag = false
      
//     }
//     return flag; 
// }

  // const onSavaClick = () => {
  //   const name = userInfo.name;
  //   regName(name) 
  //   const idCard = userInfo.idCard
  //   regCode(idCard)
  //   if(regName(name) && regCode(idCard)){
  //     setIdCard() 
  //   }
  // }
  

  const onClickWechatLogin = ()=> {
    setWechatLoginShow(true)
  }
  

  const onSendClick = (val) => {
    const name = val.name;
    const idCard = val.idcard
    setIdCard(name, idCard)
  }
  const setIdCard = async(name, idCard) => {
    try{
      const res = await UserApi.sendIdCard(name,idCard)
      if(res && res.success) {
        setUserInfo({
          ...userInfo,
          name, 
          idCard,
          isReal: true,
        })
        localStorage.setItem('isReal', true)
      }else {
        message.error(res.data.message)
      }
    }catch(e){
      console.log(e,'err')
    }
  }


  return(
    <div className={styles.container}>
        <div className={styles.user_wrap}>
          <div className={styles.user_info}>
            <div className={styles.title_wrap}>
              <span className={styles.title}>账号信息</span>
              <span className={styles.sub_title}>修改账号信息请至互医网App</span>
            </div>
            <div className={styles.user_del}>
              <span className={styles.user_title}>头像和昵称</span>
              <div className={styles.flex_box}>
                {userInfo?.avatar && <img className={styles.avatar} src={userInfo.avatar} alt='' />}
                <span className={styles.user_text}>{userInfo.nickname}</span>
              </div>
            </div>
            <div className={styles.user_del} style={{marginTop: '9px'}}>
              <span className={styles.user_title}>性别</span>
              <span className={styles.user_text}>
                {
                  userInfo.sex === null
                  ?"未填写"
                  :(userInfo.sex ===1 ? '男' : '女')
                }
              </span>
            </div>
            <div className={styles.user_del} style={{marginTop: '9px'}}>
              <span className={styles.user_title}>手机号</span>
              <span className={styles.user_text}>{userInfo.tel}</span>
            </div>
            <div className={styles.title_wrap} style={{marginTop: '39px'}}>
              <span className={styles.title}>实名认证</span>
              <span className={styles.sub_title}>一经填写无法修改</span>
            </div>
            {
              !userInfo.isReal
              ?<div>
                <Form
                  
                  preserve={false} 
                  className='account-from'
                  onFinish={(values) => onSendClick(values)}
                >
                  <Form.Item
                    name="name"
                    label="姓名"
                    
                    rules={[
                      {
                        required: true,
                        message: '请正确填写姓名',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value === null || value === undefined || value === '') {
                            return Promise.resolve();
                          }else if(value && value.length >0 && value.length <= 2) {
                            let reg1 = /^[\u4E00-\u9FA5\uF900-\uFA2D]+$/
                              if(!reg1.test(value)){
                                return Promise.reject('请正确填写姓名');
                              }else {
                                return Promise.resolve();
                              }
                          }else if(value && value.length >2){
                            let reg2 =  /^[\u4E00-\u9FA5\uF900-\uFA2D]+[\u4E00-\u9FA5\uF900-\uFA2D·s]+[\u4E00-\u9FA5\uF900-\uFA2D]+$/;
                            if(!reg2.test(value)){
                              return Promise.reject('请正确填写姓名');
                            } 
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  > 
                    <Input placeholder='请输入真实姓名' />
                  </Form.Item>
                  <Form.Item
                    name="idcard"
                    label="身份证"
                    style={{marginTop: '20px'}}
                    rules={[
                      {
                        required: true,
                        message: '请正确填写身份证',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value === null || value === undefined || value === '') {
                            return Promise.resolve();
                          }else if(value && value.length===15){
                            if(parseInt(value.substring(6,10))%4===0 || parseInt(value.substring(6,10))%400===0){
                                let reg1 = /[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/
                                if(!reg1.test(value)){
                                  return Promise.reject('请正确填写身份证');
                                }else {
                                  return Promise.resolve();
                                }
                            }else{
                                let reg2 = /[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/
                                
                                if(!reg2.test(value)){
                                  return Promise.reject('请正确填写身份证');
                                  
                                }else {
                                  return Promise.resolve();
                                }
                                
                            }
                    
                          }else if(value && value.length===18){
                              if(parseInt(value.substring(6,10))%4===0 || parseInt(value.substring(6,10))%400===0){
                                  let reg3 = /[1-9][0-9]{5}(19[0-9]{2}|20[0-9]{2})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/
                                  
                                  if(!reg3.test(value)){
                                    return Promise.reject('请正确填写身份证');
                                  }else {
                                    return Promise.resolve();
                                  }
                              }else{
                                  let reg4 = /[1-9][0-9]{5}(19[0-9]{2}|20[0-9]{2})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/
                                  if(!reg4.test(value)){
                                    return Promise.reject('请正确填写身份证');
                                    
                                  }else {
                                    return Promise.resolve();
                                  }
                              }
                          }else if(value && value.length !==18 && value.length !==15){
                            return Promise.reject('请正确填写身份证');
                          }
                          
                        },
                      }),
                    ]}
                  > 
                    <Input 
                      placeholder='请输入身份证号' 
                    />
                  </Form.Item>
                  <Form.Item>
                    <button className={styles.save_btn}>
                      保存
                    </button>
                  </Form.Item>
                </Form>
                
              </div>
              :<div>
                <div className={styles.user_del}>
                  <span className={styles.user_title}>姓名</span>
                  <span className={styles.user_text}>{userInfo.name}</span>
                </div>
                <div className={styles.user_del} style={{marginTop: '9px'}}>
                  <span className={styles.user_title}>身份证号</span>
                  <span className={styles.user_text}>{userInfo.idCard}</span>
                </div>
              </div>
            }
            <div className={styles.title_wrap} style={{marginTop: '36px'}}>
              <span className={styles.title}>社交账号关联</span>
            </div>
            {wechatLoginShow ? 
              <WechatLogin />: 
              <div className={styles.wechat_wrap}>
                  <img className={styles.wechat} alt='' src={Images.modal.weChat}/>
                  {userInfo.wechat
                    ? <span className={styles.wechat_tip}>微信已关联</span>
                    : <button className={styles.wechat_btn} onClick={onClickWechatLogin}>关联微信</button>
                  }
              </div>
            }
          </div>

        </div>
        <Footer style={{marginTop: '80px', marginBottom: '26px'}} />
        <ToTop onToTopClick={() => window.scrollTo(0, 0)}/>
    </div>
  )
}

export default AccountSetting
