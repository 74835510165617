import React from 'react';
import * as styles from './Footer.module.css'

function Footer(props) {
  return(
      <div className={styles.info_text} style={props.style}>
        Copyright © 2019 上海忞竺科技有限公司 
        <br /> 
        沪ICP备: 19034980号
      </div>
  )
}

export default Footer