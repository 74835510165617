import React, {useState,useEffect,useRef}  from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { List,Modal, message} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import  * as styles from './Album.module.css'
import {Tools} from '../../utils'
import { Images } from '../../utils/Images';
import {AlbumApi} from '../../api/index'
import {ImgModal, Footer, ToTop} from '../../components'



function Album() {

  const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const per_page = 5
  const [size, setSize] = useState(0)
  const [defaultSize, setDefaultSize] = useState(0)
  const [imgModal, setImgModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [loadAll, setLoadAll] = useState(true)
  const scrollView = useRef()

  useEffect(() => {
    if(hasMore){
      getAlbum()
    }
    getImgSize()
  },[page,hasMore]);

  const getImgSize = async() => {
    try{
      const res = await AlbumApi.getImgSize()
      if(res && res.success) {
        setSize(res.data.size)
        setDefaultSize(res.data.default)
      }
    }catch(e) {
      console.log(e,'err')
    }
  }

  const getAlbum = async() => { 
    
    try{
      const res = await AlbumApi.getAlbum(page, per_page)
      if(res && res.success) {
        let newData = initData(res.data)
        if(page === 1) {
          setData(newData)
        }else {
          
          const newRes = data.concat(newData)
          setData(newRes)
        }
        if(res.paginationMeta.totalPages === page || res.paginationMeta.totalPages === 0) {
          setHasMore(false)
        }
        setLoading(false)
        setLoadAll(false)
      }else {
        setLoading(false)
      }
    }catch(e) {
      setLoading(false)
      console.log(e,'err')
    }
  }

  //初始化数据
  const initData = (data) => {
    const newData = data.map((item) => {
      item.manage = false
      item.data.map((inner) => {
        inner.select = false;
        return inner
      })
      return item
    })
    return newData
  }

  const handleInfiniteOnLoad = () => {
    setLoading(true)
    setTimeout(() => {
      setPage(page+1)
    },1000)
    
    
    
  }

  const onManageClick = (note_id) => {
    const newData = data.map(item => {
      if(item.note_id === note_id) {
        item.manage = true
      }
      return item;
    })
    setData(newData)
  }
  const onSelectAllClick = (note_id) => {
    const newData = data.map(item => {
      if(item.note_id === note_id) {
        item.data.map(inner => {
          inner.select = true;
          return inner
        })
      }
      return item;
    })
    setData(newData)
  }
  const onImgSelectClick =(note_id, img_id) => {
    const newData = data.map(item => {
      if(item.note_id === note_id) {
        item.data.map(inner => {
          if(img_id === inner.id){
            inner.select = !inner.select;
          }
          return inner
        })
      }
      return item;
    })
    setData(newData)
  }

  const onImgDeleteClick = (note_id) => {
    let delAry = [];
    let imgLength = 0;
    data.forEach(item => {
      if(item.note_id === note_id) {
        imgLength = item.data.length;
        item.data.forEach(inner => {
          if(inner.select) {
            delAry.push(inner.id)
          }
        })
      }
    })
    if(delAry.length === 0) {
      return message.error('请至少选择一张图片')
    }else {
      Modal.confirm({
        title: '你确定要删除吗',
        icon: <ExclamationCircleOutlined />,
        content: '',
        okText: '确认',
        cancelText: '取消',
        onCancel: () => cancleReset(note_id),
        onOk: ()=> deleteImg(note_id,imgLength, delAry)
      });
      
    }
  }

  const resetImgData = (note_id, imgLength, delAry) => {
    let newData = []
    if(imgLength === delAry.length) {
      newData = data.filter(item => item.note_id !== note_id)
    }else {
      newData = data.map(item => {
        if(item.note_id === note_id) {
          item.data = item.data.filter(inner => !inner.select)
          item.manage = false
        }
        
        return item
      })
    }

    setData(newData)
  }

  const cancleReset = (note_id) => {
    let newData=  data.map(item => {
      if(item.note_id === note_id) {
        item.manage = false
        item.data.map(inner => {
          inner.select = false
          return inner
        })
      }
      
      return item
    })
    setData(newData)
  }

  const deleteImg = async(note_id, imgLength,delAry) => {
    
    try{
      const res = await AlbumApi.deletePhoto(delAry)
      if(res && res.success) {
        resetImgData(note_id, imgLength,delAry)
      }else {
        message.error(res.message)
      }

    }catch(e) {
      console.log(e, 'err')
    }
  }




  const ImgView = (props) => {
    return (
      <div className={styles.img_view}>
        <div className={styles.img_header}>
          <span className={styles.img_title}>{props.imgData.title+ '（' + props.imgData.count +'）'}</span>
          <div className={styles.btn_wrap}>
            {props.imgData.manage && 
              <button 
                className={styles.select_btn}
                onClick={() => onSelectAllClick(props.imgData.note_id)}
              >
                全选
              </button>
            }
            {props.imgData.manage && 
              <div 
                className={styles.del_btn}
                onClick={() => onImgDeleteClick(props.imgData.note_id)}
              >
                <img className={styles.del_icon} alt='' src={Images.album.deleteIcon} />
                <span className={styles.del_txt}>删除</span>
              </div>
            }
            {!props.imgData.manage &&  
              <button 
                className={styles.mg_btn}
                onClick={() => onManageClick(props.imgData.note_id)}
              >
                管理
              </button>
            }
          </div>
        </div>
        <div className={styles.timer}>{Tools.formatTimeTerse(props.imgData.create_time)}</div>
        <div className={styles.img_wrap}>
          {
            props.imgData.data.map((inner, index) => {
              return(
                <div className={styles.img_item} key={inner.id}>
                  <img 
                    className={styles.img_del} 
                    alt='' 
                    src={inner.url} 
                    onClick={() => {
                      setImgModal(!imgModal)
                      setImgSrc(inner.url)
                    }}
                  />
                  {props.imgData.manage && 
                    <img 
                      className={styles.select_icon} 
                      alt='' 
                      src={inner.select ? Images.album.selectedIcon : Images.album.unselectIcon} 
                      onClick={() => onImgSelectClick(props.imgData.note_id,inner.id)}
                    />
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  if(loadAll) {
    return (
      <div className={styles.container}>
          <div className={styles.emptyView}>
            <img className={styles.big_load} src={Images.album.bigLoad} alt='' />
            <div className={styles.emptyText}>加载中</div>
          </div>
      </div>
    )
  }

  if(Array.isArray(data) && data.length === 0 && !hasMore ) {
    return (
      <div className={styles.container}>
          <div className={styles.emptyView}>
            <img className={styles.emptyIcon} src={Images.album.emptyIcon} alt='' />
            <div className={styles.emptyText}>暂无照片</div>
          </div>
          <ToTop onToTopClick={() => null}/>
      </div>
    )
  }



  return(
    <div className={styles.container}>
        <div className={styles.scroll_view}>
          <InfiniteScroll
            ref={scrollView}
            initialLoad={false}
            pageStart={1}
            loadMore={() => handleInfiniteOnLoad()}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <div className={styles.title}>
              {'当前已暂用空间：'+size +'M（当暂空间超过'+defaultSize+'M时，将无法在笔记中上传图片）'}
            </div>
            <List
              dataSource={data}
              split={false}
              align='center'
              renderItem={item => (
                <List.Item 
                  key={item.id}
                  style={{width: '1150px',backgroundColor: 'white',paddingLeft: 0, paddingRight: 0}}
                >
                    <ImgView
                      imgData = {item}
                    />
                </List.Item>
              )}
            >
              {loading && hasMore && (
                <div className={styles.loading_wrap}>
                  <img className={styles.loadding_icon} src={Images.album.loaddingIcon} alt='' />
                </div>
              )}
              
            </List>
            <ImgModal 
                modelIsOpen={imgModal}
                closeModal={() => setImgModal(false)}
                imgSrc={imgSrc}
              />
              {!loading && !hasMore &&
                <Footer style={{marginTop: '40px', marginBottom: '26px'}}  />
              }
          </InfiniteScroll>
        </div>

        <ToTop onToTopClick={() => scrollView && scrollView.current.scrollComponent.parentElement.scroll(0, 0)}/>
      </div>
      
    
  )
}

export default Album
