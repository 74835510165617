import React from 'react';
import './FourthFrame.css'
import { Images } from '../../../utils/Images'

export default function FourthFrame(props) {

    return (
        <div className='four-contain'>
            <div className='fourth-wrap'>
                <div className='fourth-img-wrap'>
                    <img src={Images.home.fourthGif} alt='' className='fourth-img' />
                </div>
                <div className='fourth-right'>
                    <div className='fourth-title-wrap'> 
                        <div className='fourth-title'>药典在线</div>
                        <div className='fourth-sub'>
                            常用药、必备药等超全用药知识库，
                            <br/>
                            还有各种健康小知识小百科与您分享。
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}